export const imageSrc = ({ image }) => {

	const img = image ? image.split("://") : [];

	if (image) {

		if (img.length > 1) {

			return `//${img[1]}`;

		}

		return image;

	}

	return "//static.femaledaily.com/dyn/400/images/user-pics/placeholder_user.png";

};

export async function adsDetik(site, params) {

	try {

		const urls = "https://detikads.femaledaily.com/static/ads/femaledaily/staging_femaledaily/";
		const res = await fetch(`${urls + site}/${params}.txt`);

		return await res.text();

	} catch (error) {

		process.exit(1);

	}

	return null;

}

export function ScrollToUp() {

	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});

}

export const stringUrl = (params) => {

	const tmp = Array.isArray(params)
		? params[params.length - 1].replace(/[-]/g, " ")
		: params.replace(/[-]/g, " ");

	return tmp;

};

export const slugUrl = (params) => {

	const tmp = Array.isArray(params) ? params[params.length - 1] : params;

	return tmp;

};

export const categoryName = (slug, categories) => {

	const slugs = Array.isArray(slug) ? slug[slug.length - 1] : slug;

	const tmp = categories.filter((item) => item.slug.replace(/[_]/g, "-") === slugs)[0].name;

	return tmp;

};

export async function fetcher(...args) {

	const res = await fetch(...args);

	return res.json();

}

export const setGTM = () => {

	const GTM = process.env.NEXT_PUBLIC_GTM_ID;

	return {
		__html: `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${GTM}');
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${GTM}');
			`,
	};

};

export const comSocre = () => ({
	__html: `
			var _comscore = _comscore || [];
			_comscore.push({ c1: "2", c2: "8443234" });
			(function() {
			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
			s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
			el.parentNode.insertBefore(s, el);
			})();
		`,
});

export const setGoogleAnalytics = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;
	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;

	return {
		__html: `
		var _gaq = _gaq || [];
		_gaq.push(['_setAccount', '${GaHost}']);
		_gaq.push(['_trackPageview']);

		_gaq.push(['fdn._setAccount', '${GaGeneral}']);
		_gaq.push(['fdn._setDomainName', 'femaledaily.com']);
		_gaq.push(['fdn._setAllowLinker', true]);
		_gaq.push(['fdn._trackPageview']);

		(function() {
			var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
		})(); 
		`,
	};

};

export const setGoogleAnalyticsNoscript = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaGeneral}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setGoogleAnalyticsNoscriptGaHost = () => {

	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaHost}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setVideoWidget = () => ({
	__html: `
	var mobile = Boolean(window.navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

	var isActive = false

	function handleOpen() {
    const floatingButton = document.querySelector(".floating-button");

    if (!floatingButton) {
        // console.error("Floating button not found!");
        return;
    }

    if (!mobile) {
        if (!isActive) {
            floatingButton.style.bottom = "10vh";
            floatingButton.style.right = "calc(6vw + 478px)";
        } else {
            floatingButton.style.bottom = "calc(10vh + 100px)";
            floatingButton.style.right = "4vw";
        }
    } else {
        if (!isActive) {
            floatingButton.style.bottom = "calc((100vw * 9/16) + 64px + 24px)";
        } else {
            floatingButton.style.bottom = "calc(10vh + 24px)";
        }
    }

    isActive = !isActive;
}


	function handleClose(){
		const floatingButton = document.querySelector(".floating-button");
    
		if(floatingButton) {
			if (!mobile) {
					floatingButton.style.bottom = "10vh";
					floatingButton.style.right = "4vw";
			} else {
					floatingButton.style.bottom = "10vh";
			}
		}
	}

	Video.config({
		isMobile: mobile,
		unique_code: "fd2kale",
		selector: "#vidwidget",
		domain2: "${process.env.NEXT_PUBLIC_ROOT_DOMAIN}",
		isAds: false,
		position: "fixed",
		elementId: "videoWidgetStreaming",
		handleOpen: handleOpen,
		closeWidget: handleClose,
	});

	Video.widgets.render();
		`,
});

export const uniqueId = () => {

	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;

};
